import React from 'react';
import Breadcrumb from './subcomponents/Breadcrumb'
import Registerform from './subcomponents/Registerform';
import emailjs from "emailjs-com";

const Register = () => {
  const sendEmail = (e)=>{
    e.preventDefault();
    emailjs.sendForm('service_wxug4qq','template_bc7fkzj',e.target,'OANE5LoP5rWH7F2AG').then(()=>{
      alert('Form Submitted Successfully');
      document.getElementById('name').value="";
      document.getElementById('eamil').value="";
      document.getElementById('phone').value="";
      document.getElementById('country').value="";
    });
  }
  return (
    <div>
      <Breadcrumb pagename="Register Now"/>
      <div className="container-contact register">
        <div className="row form">
        <p className="sub-heading fee-sub">Madni Quran Academy</p>
        <h2 className="heading contact-heading">Student Registeration</h2>
          <Registerform submitFunction={sendEmail}/>
        </div>
      </div>
    </div>
  )
}

export default Register
