import React from 'react'
import Breadcrumb from './subcomponents/Breadcrumb'
import Contactbox from './subcomponents/Contactbox'
import Form from './subcomponents/Form'
import img from '../assets/img/new-about-imgg.jpg'
import emailjs from "emailjs-com";


const Contact = (props) => {
  const sendEmail = (e)=>{
    e.preventDefault();
    emailjs.sendForm('service_wxug4qq','template_6g6qksb',e.target,'OANE5LoP5rWH7F2AG').then(()=>{
      alert('Form Submitted Successfully');
      document.getElementById('name').value="";
      document.getElementById('eamil').value="";
      document.getElementById('phone').value="";
      document.getElementById('subject').value="";
      document.getElementById('message').value="";
    });
  }
  return (
    <>
     <Breadcrumb pagename="Contact Us"/>
      <div className="contaner contact">
        <div className="row">
        <p className="sub-heading fee-sub">Madni Quran Academy</p>
        <h2 className="heading">Contact Details</h2>
          <Contactbox icon="fab fa-facebook" heading="Facebook Page" subheading="Madni Quran Academy" link="https://www.facebook.com/profile.php?id=61552392239459"/>
          <Contactbox icon="fab fa-whatsapp" heading="Whatsapp" subheading="+923202434752" link="https://wa.me/+923202434752"/>
          <Contactbox icon="fa fa-envelope" heading="Email" subheading="zainabhusain16789@gmail.com" link="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWrRlRHmwnpZgWSpkRZkQZcrpjMksQCqKJstBmNzqMKJmvnKNMHcWzmpvKjTdzGvmxGQkgjSl"/>
        </div>
        <Form img={img} submitFunction={sendEmail}/>
      </div>
    </>
  )
}

export default Contact
