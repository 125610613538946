import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/madni-logo.png'
import whatsappimg from '../assets/img/whatsapp-icon.png'
const Footer = () => {
  return (
    <>
      <div className='container-fluid footer'>
       <div className="row">
        <div className="col-lg-3 col-md-6">
          <img src={logo} alt="" />
          <p>We are your trusted source for high-quality online Quranic education. At Madni Quran Academy, we are committed to spreading the light of the Quran to students of all ages and backgrounds. </p>
        </div>
        <div className="col-lg-3 col-md-6">
          <h2 className="heading footer-heading">Company</h2>
          <ul className="footer-menu">
           
              <li><Link to='/about'><i className="fa fa-angle-right"></i> About Us </Link></li>
              <li><Link to='/contact'><i className="fa fa-angle-right"></i> Contact Us </Link></li>
              <li><Link to='/faq'><i className="fa fa-angle-right"></i> FAQs </Link></li>
              <li><Link to='/fee'><i className="fa fa-angle-right"></i> Price & Plans </Link></li>
              <li><Link to='/'><i className="fa fa-angle-right"></i> Privacy Policy </Link></li>
           
          </ul>
        </div>
        <div className="col-lg-3 col-md-6">
          <h2 className="heading footer-heading">Useful Links</h2>
          <ul className="footer-menu">
            
          <li><Link to='/'><i className="fa fa-angle-right"></i> Home </Link> </li>
          <li><Link to='/courses'><i className="fa fa-angle-right"></i> Courses </Link></li>
          <li> <Link to='/tutors'><i className="fa fa-angle-right"></i> Tutors </Link></li>
          <li><Link to='/register'><i className="fa fa-angle-right"></i> Register </Link></li>
          <li> <a href="tel:+923202434752"><i className="fa fa-angle-right"></i> Call Now </a> </li>
           
          </ul>
        </div>
        <div className="col-lg-3 col-md-6">
          <h2 className="heading footer-heading">Contact Info</h2>
          <ul className="footer-menu">
           
              
          <li> <Link to='/tutors'><i className="fa fa-map-marker-alt"></i> Pakistan </Link> </li>
          <li> <a href="tel:+923202434752"><i className="fa fa-phone-alt"></i> +923202434752 </a> </li>
          <li><a href="https://wa.me/+923202434752" target="_blank" rel="noreferrer"><i className="fab fa-whatsapp"></i> +923202434752</a> </li>
          <li><a href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWrRlRHmwnpZgWSpkRZkQZcrpjMksQCqKJstBmNzqMKJmvnKNMHcWzmpvKjTdzGvmxGQkgjSl" target="_blank" rel="noreferrer"><i className="fa fa-envelope"></i> zainabhusain16789@gmail.com</a> </li>
          <li className='social'> <a href="https://www.facebook.com/profile.php?id=61552392239459" rel="noreferrer" target="_blank"> <i className="fab fa-facebook"></i></a> <a href="https://www.instagram.com/hussainahmedmadni16?igsh=a3A0Y2x3NGUyM3hr" rel="noreferrer" target="_blank"> <i className="fab fa-instagram"></i></a></li>
           
          </ul>
        </div>
      </div>

    </div>
    <div className="copyright">
      <p>Copyright &copy; 2023 By Madni Quran Academy | Developed By <a href='https://wa.me/+923069727285' target='_blank' rel='noreferrer'>Muazzam</a> </p>
    </div>
    <a href="https://wa.me/+923202434752" rel='noreferrer' target='_blank'  class="whatsapp-icon">
      <img src={whatsappimg} alt="WhatsApp" />
    </a>


    
    </>
  )
}

export default Footer
